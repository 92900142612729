import React from "react";
import { motion } from "framer-motion";
import "./ContactSection.css";
import image from "../../images/handshake.jpg";

import instagram from "../../images/social/instagram.png";
import facebook from "../../images/social/facebook.png";

const ContactUs = () => {
  const [isSubmitted, setIsSubmitted] = React.useState(false);
  const [formData, setFormData] = React.useState({
    name: '',
    email: '',
    phone: ''
  });
  const handleInputChange = (e) => {
    const { id, value } = e.target;
    setFormData({
      ...formData,
      [id]: value
    });
  };
  const handleSubmit = async (e) => {
    e.preventDefault();
    const name = e.target.elements.name.value || "";
    const email = e.target.elements.email.value || "";
    const phone = e.target.elements.phone.value || "";
  
    // First fetch call to insert_contact.php
    const response1 = await fetch(
      "https://officeservice.co.il/php_files/insert_contact.php",
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ name, email, phonenumber: phone }),
      }
    );  
    // Second fetch call to send_sms.php
    const response2 = await fetch(
      "https://officeservice.co.il/php_files/send_sms.php",
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ name, email, phonenumber: phone }),
      }
    );
    const data1 = await response1.json();
    const data2 = await response2.json();
    
    console.log("Received data1:", data1, "Received data2:", data2.success);
    if (data1.success && data2.success) {
      console.log("Before setting isSubmitted:", isSubmitted);
      setIsSubmitted(true);
      console.log("After setting isSubmitted:", isSubmitted);
      
      setFormData({
        name: '',
        email: '',
        phone: ''
      });
    }
  };
  
  return (
    <div className="contact-section">
      <div id="home-contact-form-container">
        <div className="form-container">
          <h1 className="titleS">מעוניינים בהצעת מחיר או פרטים נוספים?</h1>
          <p className="description">
            השאירו פרטים בטופס ונציגינו יצרו איתכם קשר!
          </p>
          <form className="formS" onSubmit={handleSubmit}>
            <div className="input-container">
              <motion.input
                className="inputCon"
                type="text"
                id="name"
                required
                placeholder=" "
                onChange={handleInputChange}
              />
              <label htmlFor="name" className="home-contact-label">
                שם
              </label>
            </div>
            <div className="input-container">
              <motion.input
                className="inputCon"
                type="tel"
                id="phone"
                required
                placeholder=" "
                onChange={handleInputChange}
              />
              <label htmlFor="phone" className="home-contact-label">
                מס' טלפון
              </label>
            </div>
            <div className="input-container">
              <motion.input
                className="inputCon"
                type="email"
                id="email"
                required
                placeholder=" "
                onChange={handleInputChange}
              />
              <label htmlFor="email" className="home-contact-label">
                אימייל
              </label>
            </div>
            <motion.button className="submit-button" type="submit">
              שלח
            </motion.button>
            {isSubmitted && <div className="success-message">הטופס נשלח בהצלחה!</div>}
          </form>
        </div>
      </div>
      <div id="home-contact-details-container">
        <p id="contact-details-title">אופיס סרוויס</p>
        <p id="contact-details-description">החברה המובילה בארץ בתחום הקידום והשיווק</p>
        <p id="contact-details-social-title">תראו אותנו גם ברשתות:</p>
        <div id="contact-details-social-links">
          <a href="https://www.instagram.com/office_gift_service/" target="_blank" rel="noopener noreferrer">
            <img src={instagram} alt="instagram-link" />
          </a>
          <a href="https://www.facebook.com/profile.php?id=100005175634037" target="_blank" rel="noopener noreferrer">
            <img src={facebook} alt="facebook-link" />
          </a>
          <a 
  href="https://www.tiktok.com/@user5977800859607" 
  target="_blank" 
  rel="noopener noreferrer"
  class="tiktok-link"
>
  <svg 
    viewBox="0 0 24 24" 
    fill="currentColor" 
    class="tiktok-icon"
  >
    <path d="M19.59 6.69a4.83 4.83 0 0 1-3.77-4.25V2h-3.45v13.67a2.89 2.89 0 0 1-5.2 1.74 2.89 2.89 0 0 1 2.31-4.64 2.93 2.93 0 0 1 .88.13V9.4a6.84 6.84 0 0 0-1-.05A6.33 6.33 0 0 0 5 20.1a6.34 6.34 0 0 0 10.86-4.43v-7a8.16 8.16 0 0 0 4.77 1.52v-3.4a4.85 4.85 0 0 1-1-.1z"/>
  </svg>
</a>

        </div>
      </div>
    </div>
  );
};

export default ContactUs;