import React, { useState, useEffect } from 'react';

const TikTokAnnouncement = () => {
  const [isOpen, setIsOpen] = useState(false);

  useEffect(() => {
    const hasSeenAnnouncement = localStorage.getItem('hasSeenTikTokAnnouncement');
    if (!hasSeenAnnouncement) {
      setIsOpen(true);
      document.body.classList.add('overflow-hidden');
      localStorage.setItem('hasSeenTikTokAnnouncement', 'true');
    }
    return () => {
      document.body.classList.remove('overflow-hidden');
    };
  }, []);

  const handleClose = () => {
    setIsOpen(false);
    document.body.classList.remove('overflow-hidden');
  };

  if (!isOpen) return null;

  return (
    <div className="fixed inset-0 bg-black/80 z-[9999] flex items-center justify-center backdrop-blur-sm">
      <div dir="ltr" className="bg-gradient-to-br from-pink-50 to-purple-50 rounded-2xl p-8 max-w-md w-full mx-4 relative transform transition-all shadow-2xl border border-white/20">
        <button 
          onClick={handleClose}
          className="absolute top-4 left-4 w-8 h-8 flex items-center justify-center rounded-full bg-black/10 hover:bg-black/20 transition-all"
        >
          <span className="text-xl">×</span>
        </button>
        
        <div className="text-center space-y-6">
          <div className="space-y-2">
            <h2 className="text-3xl font-bold bg-gradient-to-r from-pink-500 to-purple-600 bg-clip-text text-transparent">
              !פתחנו עמוד טיקטוק
            </h2>
            <p className="text-xl text-gray-700 font-medium">
              !בואו להיות חלק מהקהילה שלנו
            </p>
          </div>

          <div className="relative">
            <div className="absolute inset-0 bg-gradient-to-r from-pink-500 to-purple-600 rounded-2xl blur opacity-20"></div>
            <a
              href="https://www.tiktok.com/@user5977800859607"
              target="_blank"
              dir='rtl'
              rel="noopener noreferrer"
              className="relative block bg-black hover:scale-105 active:scale-95 text-white px-8 py-4 rounded-xl transition-all duration-200 shadow-xl"
            >
              <div className="flex items-center justify-center gap-3">
                <span className="text-lg font-medium">לעמוד הטיקטוק שלנו</span>
                <svg className="w-6 h-6" viewBox="0 0 24 24" fill="currentColor">
                  <path d="M19.59 6.69a4.83 4.83 0 0 1-3.77-4.25V2h-3.45v13.67a2.89 2.89 0 0 1-5.2 1.74 2.89 2.89 0 0 1 2.31-4.64 2.93 2.93 0 0 1 .88.13V9.4a6.84 6.84 0 0 0-1-.05A6.33 6.33 0 0 0 5 20.1a6.34 6.34 0 0 0 10.86-4.43v-7a8.16 8.16 0 0 0 4.77 1.52v-3.4a4.85 4.85 0 0 1-1-.1z"/>
                </svg>
              </div>
            </a>
          </div>

          <p className="text-sm text-gray-500">
            !תוכן בלעדי, עדכונים והפתעות מחכים לכם שם
          </p>
        </div>
      </div>
    </div>
  );
};

export default TikTokAnnouncement;